import { useEffect, useMemo, useRef, useState } from "react";
import { get } from "lodash";
import YouTube,  { YouTubeProps } from "react-youtube";
import { Image, Typography } from "antd";
import { resourceType } from "../../../app/constants";
import { getYoutubeVideoId } from "../../../utils/Common";

import PdfIcon from '../common/PdfIcon';
import FileIcon from '../common/FileIcon';
import LinkIcon from '../common/LinkIcon';
import DownloadIcon from '../common/DownloadIcon';
import { useIsDesktopOrLaptop } from "../hooks/media";



const { Text } = Typography;


const FileIconFactory = {
    'pdf': PdfIcon,
    'file': FileIcon,
    'link': LinkIcon,
}

const LinkResource = (props) => {

    const { link, name } = props;

    //console.log('sectoin step', props)
    const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

    return (
        <div className="link">
            <div  className={`iconContainer${isDesktopOrLaptop ? 'Desktop' : 'Mobile'}`}>
                <LinkIcon fill='#fff' className='icon' />
            </div>
            {
                name
                    ? <p><a href={link} target="_blank">{name}</a></p>
                    : <div/>
            }
            {/* {
                link
                    ? <a href={link} target="_blank" >
                        <DownloadIcon fill="#717171" className='downloadIcon' />
                    </a>
                    : null
            } */}
        </div>
    )

}


const YoutubeResource = (props) => {

    const { link, name } = props;

    //console.log('sectoin step', props)
    const videoId = useMemo(() => getYoutubeVideoId(link), [link]);

    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);

    const containerRef = useRef();

    useEffect(() => {

        let containerWidth = containerRef.current.clientWidth ;
       // console.log('youtube width', containerWidth);
        containerWidth =  containerWidth >= 1200 ? 1200 : containerWidth;
        // h/w = 16/9 = h1/w1
        // w1/h1 = 9/16 * h1
        setWidth(containerWidth);
        setHeight((9/16) * containerWidth)

    }, [])

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

    return (
        <div className={` ${isDesktopOrLaptop ? 'youtubeDesktop' : 'youtube'}`} ref={containerRef}>
            <YouTube videoId={videoId} opts={{  width, height, }} />
            <p>{name}</p>
        </div>
    )

}

const PDFResource = (props) => {

    const { downloadLink, name, type } = props;

    //console.log('sectoin step', props)

    const IconComponent = useMemo(() => {

        //console.log('get icon type', type);

        return get(FileIconFactory, type, FileIconFactory.file);

    }, [type])


    const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

    return (
        <div className="file" >
            <div className="item" > 
            <div  className={`iconContainer${isDesktopOrLaptop ? 'Desktop' : 'Mobile'}`} >
                <IconComponent fill='#fff' className='icon' />
            </div>
            {
                name
                    ? <p>{name}</p>
                    : <div />
            }
            </div>
            {
                downloadLink
                    ? <a href={downloadLink} target="_blank" >
                        <DownloadIcon fill="#717171" className='downloadIcon' />
                    </a>
                    : <div/>
            }
        </div>
    )

}

const ImageResource = (props) => {

    const { downloadLink, name } = props;

    //console.log('sectoin step', props)

    // const [width, setWidth] = useState(100);


    // const containerRef = useRef();

    // useEffect(() => {

    //     let containerWidth = containerRef.current.clientWidth;
    //    // console.log('youtube width', containerWidth);
    //     containerWidth =  containerWidth >= 1200 ? 1200 : containerWidth;
    //     // h/w = 16/9 = h1/w1
    //     // w1/h1 = 9/16 * h1
    //     setWidth(containerWidth);
    //     //setHeight((9/16) * containerWidth)

    // }, [])

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

    return (
        <div className={`${isDesktopOrLaptop ? 'imageDesktop': 'image'}`} >
            <Image  src={downloadLink} alt={name} 
            //className={`${isDesktopOrLaptop ? 'desktopImage' : ''}`}
            //height={270}
            // width={width} 
            width='100%'
            />
            <p>{name}</p>
        </div>
    )

}

const ViewDownloadResource = (props) => {

    const { link, name } = props;

    //console.log('sectoin step', props)

    return (
        <div>
            <iframe style={{ width: '70vw', height: 270, width: 480 }} title={name} src={link} ></iframe>
            <div>
                <a copyable={{ tooltips: 'Copy link', Text: link }}
                    href={link} target="_blank">
                    {name}
                </a>
            </div>
        </div>
    )

}

export const resourceFactoryConfig = {
    [resourceType.link]: LinkResource,
    [resourceType.youtubeVideo]: YoutubeResource,
    [resourceType.pdf]: PDFResource,
    [resourceType.image]: ImageResource,
    [resourceType.viewDownload]: ViewDownloadResource,
}
