import { useQuery } from "react-query";
import { appLinks, queryKeys } from "../../app/constants";
import { httpPostAsync } from "../../utils/httpHelpers";


export function useChallengeDetails(challengeId) {

    const fetchChallengeDetailsAsync = async () => {
        try {

            console.log('env variables',  process.env);
        

            const challenge = await httpPostAsync(appLinks.challengeDetails.replace('{challengeId}', challengeId));

            console.log('challenge details', challenge);

            return challenge || {};

        } catch (error) {
            console.log('error fetching challenge details', error);
        }
    }

    const { 
        data = {}, 
        isLoading 
    } = useQuery(
        [queryKeys.challengeDetails, challengeId],
        fetchChallengeDetailsAsync,
        {
            enabled: challengeId > 0,
            refetchOnWindowFocus: false
        }
    );

    return {
        isLoading,
        data,
    }

}