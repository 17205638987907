import { useQueryParam, StringParam } from 'use-query-params';
import { useIsDesktopLarge } from "../Challenge/hooks/media";
import useSetPassword from './useSetPassword';
import '../../styles/antd.less';
import './style.scss';
import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import ForgotPasswordContainer from "./Container";
import Empty from "../../components/Empty";
import { DefaultDescription, InvalidRequest, PasswordSet, PreviousPassword } from "./description";
import { ForgotPasswordForm } from "./form/default";
import { Footer } from "./footer";


const forgotPasswordStates = {
    invalidRequest: 'invalidRequest',
    validRequest: 'validRequest',
    previousPassword: 'previousPassword',
    actionFailed: 'actionFailed',
    actionSuccess: 'actionSuccess',
}

const descriptionComponentConfig = {
    [forgotPasswordStates.invalidRequest]: InvalidRequest,
    [forgotPasswordStates.validRequest]: DefaultDescription,
    [forgotPasswordStates.previousPassword]: PreviousPassword,
    [forgotPasswordStates.actionFailed]: Empty,
    [forgotPasswordStates.actionSuccess]: PasswordSet,
}

const formComponentConfig = {
    [forgotPasswordStates.invalidRequest]: Empty,
    [forgotPasswordStates.validRequest]: ForgotPasswordForm,
    [forgotPasswordStates.previousPassword]: ForgotPasswordForm,
    [forgotPasswordStates.actionFailed]: Empty,
    [forgotPasswordStates.actionSuccess]: Empty,
}

const footerComponentConfig = {
    [forgotPasswordStates.invalidRequest]: Empty,
    [forgotPasswordStates.validRequest]: Footer,
    [forgotPasswordStates.previousPassword]: Footer,
    [forgotPasswordStates.actionFailed]: Empty,
    [forgotPasswordStates.actionSuccess]: Empty,
}

function getState(forgetPasswordDetails, setPaswordResponse) {
    try {

        if (setPaswordResponse && setPaswordResponse.code === '400.8.17') {
            return forgotPasswordStates.previousPassword;
        } else if (setPaswordResponse && setPaswordResponse.code != '0') {
            return forgotPasswordStates.actionFailed;
        } else if (setPaswordResponse && setPaswordResponse.code == '0') {
            return forgotPasswordStates.actionSuccess;
        } else if (!forgetPasswordDetails || forgetPasswordDetails.code) {
            return forgotPasswordStates.invalidRequest;
        } else if (forgetPasswordDetails && !forgetPasswordDetails.code) {
            return forgotPasswordStates.validRequest;
        } 

    } catch (error) {
        console.log(`error in ${getState.name}`, error)
        return ''
    }
}

export default () => {

    const [desktopscreen, setDesktopScreen] = useState(false);
    const { isDesktopLarge } = useIsDesktopLarge();

    useEffect(() => {

        setDesktopScreen(isDesktopLarge)


    }, [isDesktopLarge])

    console.log('screen is desktop or not ', desktopscreen);


    const [ token ] = useQueryParam('token', StringParam);


    const {
        forgetPasswordDetails = {},
        isFetching,
        isUpdating,
        onSetPasswordAsync,
        setPaswordResponse
    } = useSetPassword(token)

    // conditions
    // error getting forgot password details, if there are is an code, then show error
    // error when resetting password
    // if forgetPasswordDetails.code show error and no reset password form
    // if response.code !== 0, when setting password, different screen
    // nor just show forward password success

    const resetPasswordAsync = async (values) => {
        try {
            console.log('values', values);

            const { password } = values;

            await onSetPasswordAsync({ ref: token, password });

           // const code = get(response, 'code');

            // if (code === 0) {
            //     //success
            //     setResetCompleted(true)
            // } else if (code === '') {
            //     setResetCompleted(false)
            // }

        } catch (error) {
            console.error('error on reset password submit', error);
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const contentStyles = useMemo(() => {
        return desktopscreen ? {
            paddingTop: '4em', height: '100vh', backgroundColor: 'rgb(200 221 254)'
        } : {
            paddingTop: '4em', backgroundColor: 'rgb(200 221 254)'
        }
    }, [desktopscreen])

    useEffect(() => {
        console.log('content styles : ', contentStyles);
    }, [contentStyles])

    const {  Description, ForgotPasswordForm, Footer } = useMemo(() => {

        try {

            const state = getState(forgetPasswordDetails, setPaswordResponse);

            console.log('state', state, setPaswordResponse);

            const Description = get(descriptionComponentConfig, state) || Empty;

             const ForgotPasswordForm = get(formComponentConfig, state) || Empty;

            const Footer = get(footerComponentConfig, state) || Empty;

            return {
                Description,
                ForgotPasswordForm,
                Footer,
            }


        } catch (error) {
            return {
                Description: Empty,
                ForgotPasswordForm: Empty,
                Footer: Empty,
            }
        }
    }, [forgetPasswordDetails, setPaswordResponse])

    return (
        <ForgotPasswordContainer
        isLoading={isFetching || isUpdating}
            descriptionRenderProp={
                <Description { ...forgetPasswordDetails } />
            }
            formRenderProp={
                <ForgotPasswordForm
                    resetPasswordAsync={resetPasswordAsync}
                    onFinishFailed={onFinishFailed} />
            }
            footerRenderProp={<Footer { ...forgetPasswordDetails } />}
        />
    )


}