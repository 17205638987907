import { map } from "lodash"
import { t } from "../../utils/Translation"
import Subtitle from "./common/Subtitle"


export default ({ themes }) => {

    return (
        <div className="themeContainer">
            <div>
                <Subtitle>{t('Themes')}</Subtitle>
            </div>
            {
                map(themes, theme => {

                    return (
                        <span
                            key={theme.value}
                            className="themeItem" >
                            {theme.label}
                        </span>
                    )
                })
            }
        </div>
    )

}