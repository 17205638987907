import { useMemo } from "react";
import { get } from "lodash";
import { Card, Row } from 'antd';
import { creatorTypes } from "../../../app/constants";
import Empty from "../../../components/Empty";
import Subtitle from "../common/Subtitle";
import { t } from "../../../utils/Translation";
import { useIsDesktopOrLaptop } from '../hooks/media';

const CreatorClubView = ({ name }) => {
    return (
        <div className="club">
            <div className="content">
                <p>{name}</p>
            </div>
            {/* <div className="space-right" />
            <div className="space-right" /> */}
        </div>)
}

export const CreatorPersonView = ({ name, logoLink, initials }) => {

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

    return (

        isDesktopOrLaptop ?
            <Card style={{
                display: 'inline-block',
                marginTop: 20,
                paddingTop: '1em',
                paddingLeft: '2em',
                paddingRight: '2em'
            }}>
                <div className="person">
                    <div className="content">
                        {
                            logoLink
                                ? <img src={logoLink} alt="creator person avatar" style={{ height: 80, borderRadius: '8px' }} />
                                : (
                                    <div className="palceholder" >
                                        {/* <p className="initials">{initials}</p> */}
                                        <p className="initials"></p>
                                    </div>
                                )
                        }
                        <p>{name}</p>
                    </div>
                    {/* <div className="space-right"/>
            <div className="space-right"/> */}
                </div>
            </Card>
            :
            <div className="person">
                <div className="content">
                    {
                        logoLink
                            ? <img src={logoLink} alt="creator person avatar" style={{ height: 80, borderRadius: '8px' }} />
                            : (
                                <div className="palceholder" >
                                    {/* <p className="initials">{initials}</p> */}
                                    <p className="initials"></p>
                                </div>
                            )
                    }
                    <p>{name}</p>
                </div>
                {/* <div className="space-right"/>
            <div className="space-right"/> */}
            </div>
    )
}

export const CreatorOrganisationView = ({ name, logoLink }) => {

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop;

    return (
        isDesktopOrLaptop ?

            <Card style={{
                display: 'inline-block',
                marginTop: 36
            }}>
                {logoLink ? <img src={logoLink} alt="organisation logo" style={{ height: 80, borderRadius: '8px' }} /> : null}
                {name ? <p>{name}</p> : null}
            </Card>
            :

            <div className="organisation">
                <div className="content">
                    {logoLink ? <img src={logoLink} alt="organisation logo" style={{ height: 80, borderRadius: '8px' }} /> : null}
                    {name ? <p>{name}</p> : null}
                </div>
                {/* <div className="space-right" />
            <div className="space-right" /> */}
            </div>

    )
}

const CreatorTextView = ({ creatorText }) => {
    return (
        <div className="text">
            <div className="content">
                <p>{creatorText}</p>
            </div>
            {/* <div className="space-right" />
            <div className="space-right" /> */}
        </div>
    )
}



const creatorFactory = {
    [creatorTypes.club]: CreatorClubView,
    [creatorTypes.creator]: CreatorPersonView,
    //CreatorOrganisationView,
    [creatorTypes.organisation]: CreatorOrganisationView,
    [creatorTypes.text]: CreatorTextView,
    //test
    //  [creatorTypes.creator]: CreatorTextView,
}



export default ({ creatorType, ...rest }) => {

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop();
    const CreatorView = useMemo(() => {

        return get(creatorFactory, creatorType, Empty);

    }, [creatorType]);

    return (
        <div className={`creator${isDesktopOrLaptop ? 'Desktop' : ''}`}>
            <Row className="subtitleTextContainer" justify={`${isDesktopOrLaptop ? "center" : 'start'}`}>
                <Subtitle className='subtitleText'>{t('Created by')}</Subtitle>
            </Row>
            <Row justify={`${isDesktopOrLaptop ? 'center' : 'start'}`} >
                <div style={{
                    width: `${ isDesktopOrLaptop ? 'auto' : '100%'}`
                }}>

                <CreatorView  {...rest} />
                </div>
            </Row>
        </div>
    )

}