import { useMemo } from "react"
import Creator from "./Creator";
import PoweredBy from "./PoweredBy";



export default ({ creators = {} }) => {

    const {
        creator,
        poweredBy
    } = useMemo(() => {
        try {

            const { creator = {}, poweredBy = [] } = creators || {};

            return {
                creator,
                poweredBy,
            }

        } catch (error) {
            console.log('error destructuring creators', error);
            return {}
        }
    }, [creators])

    return (
        <div className="creatorContainer">
            { creator ? <Creator {...creator} /> : null }
            {poweredBy && poweredBy.length ? <PoweredBy poweredBy={poweredBy} /> : null }
        </div>
    )

}