import appConfig from "./config";


export const appLinks = {
    challengeDetails: `${appConfig.baseUrl}api/web/challenge/{challengeId}`,
    setPassword: `${appConfig.baseUrl}api/account/set-password`,
    forgotPasswordDetails: `${appConfig.baseUrl}api/account/forgot-password/details`,
    makeForgotpasswordRequest: `${appConfig.baseUrl}api/account/forgot-password/details`,
}

export const queryKeys = {
    challengeDetails: 'challengeDetails',
    setPassword: 'setPassword',
    forgotPasswordDetails: 'forgotPasswordDetails',
    forgotPasswordAction: 'forgotPasswordAction',
}

export const resourceType = {
    video: 'video',
    youtubeVideo: 'youtube_video',
    link: 'link',
    image: 'image',
    pdf: 'pdf',
    file: 'file',
    viewDownload: 'view_download',
}

export const creatorTypes = {
    creator: 'creator',
    organisation: 'organisation',
    club: 'club',
    text: 'text',
}

export const publishStatusMap = {
    listed: 'Published',
    unlisted: 'Not published'
}