import { get } from "lodash"
import { useMemo } from "react"
import { Grid } from 'antd'
import YouTube from "react-youtube";
import Empty from "../../components/Empty"
import { getYoutubeVideoId } from "../../utils/Common";

const { useBreakpoint } = Grid;
const YoutubeVideo = ({ videoLink }) => {

    const videoId = useMemo(() => getYoutubeVideoId(videoLink), [videoLink]);

    return (
        <div>
            {
                 videoLink ?
                 <YouTube videoId={videoId} height={270} width={480} />
                 : null
            }
        </div>
    )

}

const CoverImage = ({ imageUrl }) => {
    const screens = useBreakpoint();
 
    const className = screens.lg ? 'coverImageDesktop' : 'coverImageMobile'
    return (
        imageUrl ?
        <img src={imageUrl} width={'100%'} className={className} />
        : null
    )
}

const componentFactoryConfig = {
    image: CoverImage,
    youtubeVideo: YoutubeVideo,
}


export default ({ type, ...rest }) => {

    const DisplayComponent = useMemo(() => {
        return get(componentFactoryConfig, type) || Empty;
    }, [type])

    return (
        <div>
            <DisplayComponent {...rest} />
        </div>
    )

}