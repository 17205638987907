import React from "react";
import { Card, Row, Col, Avatar, Button } from "antd";
import { useNavigate } from "react-router-dom";


export function DefaultDescription({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Set New password</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >Set a new password</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function PreviousPassword({ user }) {

    return (
        <React.Fragment>
            <Row justify="center" style={{ paddingBottom: '.9em' }}>
                <Avatar src="images/keyvector.svg" size={60} />
            </Row>
            <Row justify="center">
                <p >{user}</p>
            </Row>
            <Row justify="center">
                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Set New password</p>
            </Row>
            <Row justify="center">
                <Col span={12}>
                    <p style={{ textAlign: 'center' }} >Your new password must be different from the previous used password</p>
                </Col>
            </Row>
        </React.Fragment>
    )

}

export function InvalidRequest() {

    const navigate = useNavigate();

    const goHome = () => {
        try {

            navigate(`/`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row justify='center'>
                    <Col span={12}>
                        <Card style={{ padding: '1em' }}>
                            <Row justify='center' style={{ padding: '1em' }}>
                                <Avatar src="images/cautionvector.svg" size={60} />
                            </Row>
                            <Row justify="center">
                                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Oops! Something is not right</p>
                            </Row>
                            <Row justify='center'>
                                <Col>
                                    <p style={{ textAlign: 'center' }}>Forgot password request might have expired, please make forget password request again or contact us.</p>
                                </Col>
                            </Row>
                            <Row justify='center' style={{ padding: '1em' }}>
                                <Button onClick={goHome} type="link">Back to Home</Button>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )

}

export function PasswordSet() {

    const navigate = useNavigate();

    const goHome = () => {
        try {

            navigate(`/`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row justify='center'>
                    <Col span={12}>
                        <Card style={{ padding: '1em' }}>
                            <Row justify='center' style={{ padding: '1em' }}>
                                <Avatar src="images/cautionvector.svg" size={60} />
                            </Row>
                            <Row justify="center">
                                <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Password set</p>
                            </Row>
                            <Row justify='center'>
                                <Col>
                                    <p style={{ textAlign: 'center' }}>Password set successfully.</p>
                                </Col>
                            </Row>
                            <Row justify='center' style={{ padding: '1em' }}>
                                <Button onClick={goHome} type="link">Back to Home</Button>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )

}