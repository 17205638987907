
import { t } from '../../utils/Translation';
import { Row, Space, Button } from 'antd';
import ClockIcon from './common/ClockIcon';
import MedalIcon from './common/MedalIcon';
import HeartIcon from './common/HeartIcon';

export default ({ likes, duration, xp, textStyle }) => {

    return (
        <div className='infoContainer'>
            <Space align='center' style={{
                marginRight: 14
            }}>

                <HeartIcon fill="#717171" className="icon" style={{
                    marginTop: 5,
                    padding: 0,
                    height: 24,
                    width: 24,

                }}
                />
                <span style={{
                    fontSize: 14,

                }}>{likes ? likes : ''}</span>
            </Space>
            <Space align='center' style={{
                marginRight: 14
            }} >
                <div><ClockIcon fill="#717171" className='icon' style={{
                    marginTop: 5,
                    padding: 0,
                    height: 24,
                    width: 24,

                }} /></div>
                <span>{duration}</span>
            </Space>
            <Space align='center' style={{
                marginRight: 14
            }}>
                <div><MedalIcon fill="#717171" className='icon' style={{
                    marginTop: 5,
                    padding: 0,
                    height: 24,
                    width: 24,

                }} /></div>
                <span>{xp} {t('Xp points')}</span>
            </Space>
        </div>
    )

}