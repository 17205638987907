import { useMemo } from "react";
import { Divider } from "antd";
import { get, map } from "lodash"
import Empty from "../../../components/Empty";
import { resourceFactoryConfig } from "./Views";
import Subtitle from "../common/Subtitle";
import { t } from "../../../utils/Translation";
import { useIsDesktopOrLaptop } from "../hooks/media";


const ResourceElement = (props) => {

    //console.log('resource elment', type)
    const { type } = props;

    const ResourceComponent = useMemo(() => {
        return get(resourceFactoryConfig, type) || Empty
    }, [type]);

    return (<ResourceComponent {...props} />)
}


export default ({ resources = [] }) => {

    const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

    return (
        resources && resources.length
            ?
            <div className="resourcesContainer">
                <Subtitle className={`subtitle ${isDesktopOrLaptop ? 'resourceTitleDesktop subtitleText': ''}`}>{t('Resources')}</Subtitle>
                <div className="resourceItems">
                    {
                        map(resources, (resource, index) => {
                            return (
                                <div key={resource.id} className={`resourceItem${isDesktopOrLaptop ? 'Desktop' : '' }`} >
                                    {index > 0 ? (<Divider  />) : null}
                                    <ResourceElement key={resource.id} {...resource} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            : null
    )
}