import React from "react";
import { Col, Row, Image } from "antd";


export function Footer({ organisation, logoLink, }) {

    return (
        <Col span={24} style={{ padding: '2em 2em', paddingBottom: '2em', backgroundColor: 'rgb(246 249 255)' }}>
            <Row justify="center">
                <p style={{ fontSize: '1.5em', marginBottom: '8px' }}>{organisation}</p>
            </Row>
            <Row justify="center">
                <div style={{
                    height: '4px',
                    width: '4em',
                    backgroundColor: '#eb2f96'
                }}></div>
            </Row>
            <Row justify="center" style={{ paddingTop: '1em' }}>
                <Image preview={false} src={logoLink} style={{ height: '8em', borderRadius: '0.3em', marginTop: '1em' }} />
            </Row>
        </Col>
    )

}