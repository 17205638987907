
import { t } from '../../utils/Translation';
import DifficultyIcon from './common/DifficultyIcon';
import UserGroupIcon from './common/UserGroupIcon';
import UserIcon from './common/UserIcon';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;



export default ({ difficulty, enrolled = 0, isFacilitationNeeded = false }) => {
    const screens = useBreakpoint();

    return (
        <div className={screens.lg ? 'statsContainerDesktop' : 'statsContainer'}>
            
            <div className='statsItem'>
                <div><DifficultyIcon fill='#717171' className='skillIcon' /></div>
                <h2>{difficulty}</h2>
                <p>{t('Skill Level')}</p>
            </div>
            <div className='seperator'/>
            <div className='statsItem'>
                <div><UserGroupIcon fill='#717171' className='enrolledIcon' /></div>
                <h2>{enrolled > 0 ? enrolled : '-'}</h2>
                <p>{t('Taken by')}</p>
            </div>
            <div className='seperator'/>
            <div className='statsItem'>
                <div><UserIcon fill='#717171' className='facilitationIcon' /></div>
                <h2>{isFacilitationNeeded ? t('Yes') : t('No')}</h2>
                <p>{t('Facilitation')}</p>
            </div>
          
        </div>
    )

}