import { map } from "lodash"
import FilledCircleIcon from './common/DotIcon';
import Paragraph from "./common/Paragraph";
import Subtitle from "./common/Subtitle";
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

export default ({ steps = [] }) => {

const screens = useBreakpoint();

const stepsContainerClass = screens.lg ? 'stepsContainerDesktop' : 'stepsContainer'

    return (
        <div class={stepsContainerClass}>
            <Subtitle className="subtitleText" >Steps</Subtitle>
            <ul class="stepsList">
                {
                    map(steps, step => {
                        return (
                            <li key={step.id}  >
                                <div class="listItem">
                                    <FilledCircleIcon fill="#b668b3" className="stepDot" />
                                    <Paragraph>{step.title}</Paragraph>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )

}