import { map } from "lodash"
import { t } from "../../../utils/Translation";
import Subtitle from "../common/Subtitle";
import { Grid, Card, Row, Col } from 'antd';

const { useBreakpoint } = Grid;

const CreatorOrganisationView = ({ name, logoLink }) => {
    const screens = useBreakpoint();
    return (
        screens.lg ? 
        <Col>
        <Card>
             <div className="content">
                {logoLink ? <img src={logoLink} alt="organisation logo" style={{ height: 80, borderRadius: '8px' }} /> : null}
                {name ? <p>{name}</p> : null}
            </div>

        </Card>
        </Col>
        :
        
        <div className="item">
            <div className="content">
                {logoLink ? <img src={logoLink} alt="organisation logo" style={{ height: 80, borderRadius: '8px' }} /> : null}
                {name ? <p>{name}</p> : null}
            </div>
            {/* <div className="space-right" />
            <div className="space-right" /> */}
        </div>
    )
}

export const PoweredByItem = (props) => {
    const screens = useBreakpoint();

    const { logoLink } = props;

    return (
        <div style={{ 
            width:`${screens.lg ? 'auto' : '100%'}`, 
            marginLeft:`${screens.lg ? '1.5em' : '0px'}`,
            marginTop: `${screens.lg ? '0px' : '1em'}`
            
            }}>
           
            <CreatorOrganisationView logoLink={logoLink} />
          
        </div>
    )
}

export default ({ poweredBy = [] }) => {
const screens = useBreakpoint();
const poweredByClassname = screens.lg ? "poweredByDesktop" : "poweredBy"
    return (
        <div className={poweredByClassname}>
            <div className="items">
                <Subtitle className="subtitleText">{t('Powered by')}</Subtitle>
                <div />
                
                <Row  justify={`${screens.lg ? 'center' : 'start'}`}>
                {
                    map(poweredBy, (item) => {

                        return (<PoweredByItem  key={item.id}  {...item} />)

                    })
                }
                </Row>
            </div>
        </div>
    )

}