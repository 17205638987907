import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import Home from './Home';
import Terms from './views/Terms';
import Privacy from './views/Privacy';
import { Challenge, ForgotPassword } from './screens';
import './App.scss';

const queryClient = new QueryClient({ refetchOnWindowFocus: false });

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/challenge/:challengeId" element={<Challenge />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
      </QueryParamProvider>
    </QueryClientProvider>
  );
}

export default App;
