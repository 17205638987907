import { map } from "lodash"
import { Row, Col} from 'antd';
import { t } from "../../utils/Translation"
import Subtitle from "./common/Subtitle"


export default ({ skills = [] }) => {

    return (
        <div className="skillsContainer">
            <Subtitle>{t('Skills')}</Subtitle>
            <Row className="itemsContainer">
                {
                    map(skills, (skill, idx) => {
                        return (
                            <Col key={skill.value} className="skillItem">
                                <span className="skillItemText" >{skill.label}</span>
                                {
                                    (skills.length - 1 !== idx)
                                        ? (<div className="dot" />)
                                        : null
                                }
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )

}