import { get } from "lodash";
import { useEffect, useMemo } from "react";
import { Row, Col, Layout, Spin, Card } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import CoverDisplay from "./CoverDisplay";
import Creators from "./Creators";
import Description from "./Description";
import Info from "./Info";
import Resources from "./Resources";
import Skills from "./Skills";
import Stats from "./Stats";
import Steps from "./Steps";
import Themes from "./Themes";
import { useChallengeDetails } from "./useChallengeDetail";

import Logo from '../../vectors/logomin.svg';
import '../../styles/antd.less';
import './Challenge.scss';

import { t } from "../../utils/Translation";
import { useMediaQuery } from "react-responsive";
import { useIsDesktopOrLaptop, useIsTabletOrMobile } from "./hooks/media";

const { Content, Header } = Layout;


export const DesktopOrLaptop = ({ children }) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    return isDesktopOrLaptop ? children : null
}

export const Tablet = ({ children }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
    return isTabletOrMobile ? children : null
}

// const BigScreen = ({ children }) => {
//     const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
//     return isBigScreen ? children : null
// }

export default () => {

    let { challengeId } = useParams();

    const navigate = useNavigate();

    useEffect(() => {

        if (!challengeId || challengeId <= 0) {
            navigate('/', { replace: true });
        }

    }, [challengeId])

    const { isLoading, data = {} } = useChallengeDetails(challengeId);

    const {
        title,
        organisation,
        coverImageType,
        imageUrl,
        videoLink,
        xp,
        duration,
        difficulty,
        isFacilitationNeeded,
        skills = [],
        themes = [],
        customIntro,
        introText,
        description,
        resources = [],
        creators = [],
        likes = 0,
        usersEnrolled = 0,
    } = data;

    const steps = useMemo(() => {
        // currently there is one section of type steps
        return get(data, 'sections[0].items') || []
    }, [data])

    //console.log('challenge details', data);

    return (
        <Layout>
            <div className="challengeContainer antdContainer challengeNamespace">
                <Spin spinning={isLoading}>
                    <DesktopOrLaptop>
                        <Header>
                            <img src={Logo} alt='logo image' height='50px'
                                style={{ marginLeft: '5em', }}
                            />
                        </Header>
                        <div className="desktop">
                            <Row className="desktopCoverDisplay" justify="center"  >
                               <Card>
                                <Row>
                                <Col lg={12}>
                                    <Row
                                        style={{ paddingRight: '40px',}}>
                                        <Col flex={1} >
                                           
                                            <h1 className="challengeTitle">{title}</h1>
                                            <Info likes={likes} duration={duration} xp={xp} />
                                            <Themes themes={themes} />
                                            <Skills skills={skills} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12}>
                                    <CoverDisplay type={coverImageType} imageUrl={imageUrl} videoLink={videoLink} />
                                </Col>
                                </Row>
                                </Card>
                            </Row>
                        </div>
                    </DesktopOrLaptop>
                    <Tablet>
                        <div className="mobile">
                            <CoverDisplay type={coverImageType} imageUrl={imageUrl} videoLink={videoLink} />
                        </div>
                        <Row
                            style={{
                                marginLeft: 18,
                                marginRight: 18
                            }}>
                            <Col flex={1} >
                                <h1 className="challengeTitle">{title}</h1>
                                <Info likes={likes} duration={duration} xp={xp} />
                                <Themes themes={themes} />
                            </Col>
                        </Row>
                    </Tablet>
                    <div className="infoContent">
                        <Description title={t("Description")} text={description} />
                        <Stats difficulty={difficulty} enrolled={usersEnrolled} isFacilitationNeeded={isFacilitationNeeded} />
                        <Tablet>
                            <Skills skills={skills} />
                        </Tablet>
                        <Description title={t("More Details")} text={introText} />
                        <Steps steps={steps} />
                        <Creators creators={creators} />
                        <Resources resources={resources} />
                    </div>
                </Spin>
            </div>
        </Layout>
    )

}