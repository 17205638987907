import { Typography, Grid } from "antd";
import Subtitle from "./common/Subtitle";

const { Paragraph } = Typography;

const { useBreakpoint } = Grid; 
export default ({ title, text, rows = 5 }) => {

    //const [key, setKey] = useState(0)
    const screens = useBreakpoint();
    const className = screens.lg ? 'desktopDescription' : 'mobileDescription'
    return (
        <div className = {className}>
          
            <Subtitle className="subtitleText">{title}</Subtitle>
            <Paragraph
                className="paragraph"
                ellipsis={{
                    rows: rows,
                    expandable: true,
                    symbol: 'Show more',
                    // onExpand: e => {
                    //     console.log('on expand', e);
                    //     setTimeout(() => {
                    //         console.log('set expandable false');
                    //         setKey(k => k + 1)
                    //     }, 2000);
                    // }
                }}>
                {text}
            </Paragraph>
        </div>
    )

}