import React from 'react';
import Lottie from 'react-lottie';
import WhoHow from './views/WhoHow';
import SiteNav from './views/SiteNav';
import ChildtrenParkAni from './animations/94289-teamwork.json';
import { t } from './utils/Translation';
import logo from './vectors/logomin.svg';
import SkillsIcon from './vectors/skills.svg';
import DoingIcon from './vectors/doing.svg';
import ShowcaseIcon from './vectors/showcase.svg';
import DiscoverIcon from './vectors/discover.svg';
import CollaborateIcon from './vectors/collaborate.svg';
import UnleashIcon from './vectors/unleash.svg';
import ActivitiesIcon from './vectors/activities.svg';
import QuestsIcon from './vectors/quests.svg';
import CoursesIcon from './vectors/courses.svg';
import ComingSoonIcon from './vectors/comingsoon.svg';
import YouthClubsIcon from './vectors/youthclubs.svg';
import ParentsIcon from './vectors/parents.svg';
import EmployersIcon from './vectors/employers.svg';
import './Home.scss';


function Home() {
  return (
    <div className="Home">
      <div className="WhiteSection HomeSection">
        <div id="topStrip" className="TopStrip">
          <div className="LanguageSelect">
            En
          </div>
        </div>
        <header>
          <img src={logo} className="App-logo" alt="logo" />
          <div className="MainMenu">
            <ul>
              {/* <li><a href="#how">{t('How it works')}</a></li> */}
              <li><a href="#method">{t('Methodology')}</a></li>
              <li><a href="#contact">{t('Contact')}</a></li>
            </ul>
          </div>
          <div style={{ display: 'none' }} className="Actions">
            <button>{t('Donate')}</button>
            <button className="demoButton">{t('Book a demo')}</button>
          </div>
        </header>
        <div className="Banner">
          <div class="Banner__slogan">
              <h1>{t('Digital solution for innovative educational organisations')}</h1>
              <i>{t('Management and communication instruments that help you work efficiently and save time for what matters most - creating innovative hybrid learning experiences.')}</i>
              <b>{t('Engage your learners, track their progress and showcase their impact.')}</b>
              <button onClick={() => window.location.href = '#contact' }>{t('Get in touch')}</button>
          </div>
          <div class="Banner__image">
            <img alt="Preview" src="./images/preview.png" />
          </div>
        </div>
        <div class="Banner__footer">
            <ul>
              <li><img src={ShowcaseIcon} alt={t('Showcase achievements')} /><b>{t('Showcase achievements')}</b></li>
              <li><img src={DoingIcon} alt={t('Learn by doing')} /><b>{t('Learn by doing')}</b></li>
              <li><img src={SkillsIcon} alt={t('Build skills')} /><b>{t('Build skills')}</b></li>
            </ul>
        </div>
      </div>
      <div className={"WhiteSection"}>
      <div className="Content OurMission">
        <div className="SideImage">
          <div className="ImageSide">
            <Lottie 
            options={{
              loop: true,
              autoplay: true,
              animationData: ChildtrenParkAni,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            />
          </div>
          <div className="TextSide">
            <h2>{t('OUR VISION')}</h2>
            <h1>{t('Everyone thrives in an unpredictable, complex and ever-changing world.')}</h1>
            <h2>{t('WHY WE EXIST')}</h2>
            <p>
              Inequality of opportunity in education is one of the fundamental problems in our society today. We believe that everyone should have access to engaging, relevant and high-quality learning experiences.
            </p><br/>
            <p>
              We empower organisations to scale their impact by bringing together the best educators and learning experiences to help anyone develop skills and competencies no matter their background or where they are from.
            </p>
          </div>
        </div>
      </div>
      </div>
      {/* <div id="how"  className="WhiteSection noPadding">
        <WhoHow />
      </div> */}
      <div id="method" className="WhiteSection">
        <div className="Content">
          <h1 className='hasSubHeading'>{t('Choose your learning mode')}</h1>
          <h2>{t('Develop 21st century skills, anytime and anywhere!')}</h2>
          <h2>{t('Join a community of driven change markers')}</h2>
          <ul className="ContentList">
            <li>
              <i><img src={ActivitiesIcon} alt={t('Activities')} /></i>
              <h3>{t('Activities')}</h3>
              <p>{t('Practical challenge to learn at own pace everyday for 15-20min.')}</p>
            </li>
            <li>
              <i><img src={QuestsIcon} alt={t('Quests')} /></i>
              <h3>{t('Quests')}</h3>
              <p>{t('Learn outside the classroom though our local quests.')}</p>
            </li>
            <li>
              <i><img src={CoursesIcon} alt={t('Courses')} /></i>
              <h3>{t('Courses')}</h3>
              <p>{t('In person, online or hybrid we\'ve got you covered!')}</p>
            </li>
            <li>
              <i><img src={ComingSoonIcon} alt={t('Coming soon')} /></i>
              <h3>{t('Coming soon')}</h3>
              <p>{t('Opportunities, live webinars with experts, bootcamps, club events, group challenges, mentoring and more')}</p>
            </li>
          </ul>
        </div>
      </div>
      <div style={{ display: "none" }} id="who" className="WhiteSection">
        <div className="Content">
          <h1>{t('Partner with us')}</h1>
          <ul className="ContentList">
            <li>
              <i><img src={YouthClubsIcon} alt={t('Schools and youth organisations')} /></i>
              <h3>{t('Schools and youth organisations')}</h3>
              <p>{t('To be successful today we need to bring together the HEAD, HAND & HEART. This is where we come in: we are the only platform that bridges together the gap between KNOWLEDGE with PRACTICE in the real world. We expand their programmes and small-group support and programmes.')}</p>
              <p>{t('ONE partners with schools to support students aged 11-18 years old. Schools become part of a platform which encourages students to develop  essential transferable skills and showcase their achievements, both academic and not.')}</p>
              <p>{t('Schools have access to real-time analytical tools to measure the progress of  individual students, taking a data-driven, personal approach to their development. The digital record of achievement is an engaging, accessible tool to help  students develop the skills that will support them in their future.')}</p>
            </li>
            <li>
              <i><img src={ParentsIcon} alt={t('Parents and teachers')} /></i>
              <h3>{t('Parents and teachers')}</h3>
              <p>{t('We understand how time consuming is for parents and teachers to find new ways to engage with their young people. We can created a all-in-one place for your next session / lesson and free time activities and see in  real time the effort and skills they are developing.')}</p>
              <b>{t('100% youth approved')}</b>
            </li>
            <li>
              <i><img src={EmployersIcon} alt={t('Employers')} /></i>
              <h3>{t('Employers')}</h3>
              <p>{t('We can also help with Talent Finder, creating awareness and supporting your opportunities by working with you to create challenges that students can take part in.')}</p>
              <p>{t('Supporting the HR leaders everywhere with a unique benefits that  increases productivity by letting working parents and their caregivers focus on work, all the while knowing their kids are engaged and exploring their interests in-depth.')}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="WhiteSection">
        <div className="Content">
            <h1>{t('Supported by')}</h1>
            <div className="SupportedBy">
              <a rel="noreferrer" target="_blank" href="https://www.noi-orizonturi.ro/"><img alt="New Horizons Foundation" src="./images/supportedby/newhorizons.jpg" /></a>
              <a rel="noreferrer" target="_blank" href="https://bruntwood.co.uk/scitech/business-support/programmes/e4f/"><img alt="E4F" src="./images/supportedby/e4f.jpg" /></a>
              <a  rel="noreferrer"target="_blank" href="https://eyfoundation.com/"><img alt="EY Foundation" src="./images/supportedby/ey.jpg" /></a>
              <a  rel="noreferrer"target="_blank" href="https://startups.microsoft.com/"><img alt="Microsoft for Startups" src="./images/supportedby/msrocket.jpg" /></a>
              <a  rel="noreferrer"target="_blank" href="https://www.ashoka.org/en-gb/country/united-kingdom"><img alt="Ashoka Changemakers" src="./images/supportedby/ashoka.jpg" /></a>
              <a  rel="noreferrer"target="_blank" href="https://www.presencing.org/"><img alt="Presencing Institute" src="./images/supportedby/presencing.jpg" /></a>
            </div>
            
        </div>
      </div>
      <div id="contact" className="WhiteSection">
        <div className="Content">
            <h1>{t('Contact')}</h1>
            <ul className="Address">
              <li>MOONSHOT GLOBAL CONSULTING LTD</li>
              <li>Suite2a Blackthorn House</li>
              <li>St Pauls Square</li>
              <li>Birmingham, West Midlands B3 1RL</li>
              <li>contact@onelearning.app</li>
            </ul>
        </div>
      </div>
      <div className="Footer">© 2022 ONE Learning App. All Rights Reserved &bull; <a target="_blank" href="/terms">Terms of use</a> &bull; <a target="_blank" href="/privacy">Privacy</a></div>
    </div>
  );
}



export default Home;